import { graphql } from "gatsby";
import { IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

interface AboutPageProps {
  location: Location;
  data: {
    bgImage: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const AboutPage: React.FC<AboutPageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO
        title="#1 Residential and Commercial Painting Company in Charlotte"
        description="We provide top-of-the-line, luxurious and durable finishes that will transform your space into world-class art."
        slug={location.pathname}
      />

      <section className="overflow-hidden">
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-16 lg:items-center">
            <div>
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6">
                About Us
              </h2>
              <p className="mb-4">
                Providing the Charlotte area with professional residential and
                commercial painting services for over 15 years.
              </p>
              <p className="mb-4">
                At NoDa Painting, we are committed to delivering
                top-of-the-line, luxurious and durable finishes that will
                transform any space into world-class art. We have developed our
                excellent reputation by focusing on craftsmanship, customer
                service, and keeping up with the industry's latest developments
                and trends.
              </p>
              <p className="mb-5">
                Feel free to contact me directly with any questions or needs.
              </p>
              <p>
                <span className="block text-lg font-semibold text-text-heading leading-none">
                  Omar Salomon
                </span>
                <span className="italic text-sm leading-none">
                  Owner and operator
                </span>
              </p>
            </div>
            <div className="justify-self-end w-full">
              <div className="relative">
                <StaticImage
                  src="../images/omar-salomon.jpg"
                  alt="Omar Salomon"
                  objectFit="cover"
                  width={538}
                  height={675}
                  className="z-10"
                />
                <div className="absolute top-8 -left-8 max-h-full max-w-full w-[538px] h-[675px] z-0 overflow-hidden">
                  <div className="bg-[#f8f8fa] w-full h-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    bgImage: imageSharp(original: { src: { regex: "/exterior-painting-8/" } }) {
      id
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;
